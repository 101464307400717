table {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid black
  }
  

  th, td {
    /* padding: 8px; */
    text-align: center;
    /* border: 1px solid #ccc; */
  }

  th {
    padding: 8px;
  }
  

  /* Optional: Style the table header */
  thead tr {
    border-bottom: 1px solid black;
    background-color: #fff !important;
 
  }