.content-header{
    /* height: 85px; */
    background-color: #D9D9D9;
    box-shadow: 0px 4px 4px rgba(0,0,0,.25);
}

.content-header-modal{
    height: 65px;
    background-color: #EFEFEF;
    box-shadow: 0px 4px 4px rgba(0,0,0,.25);
    display: flex;   
    justify-content: space-between; 
}

.content-header-title{
    font-size: 2rem;
    font-weight: 700;
    /* width : 300px; */
    height: 70px;
    background-color: #EFEFEF;
    text-align: center;
    border-bottom-right-radius: 19px;
    box-shadow: 0px 4px 4px rgba(0,0,0,.25),4px 0px 4px rgba(0,0,0,.25) ;
}
.content-header-center{
    justify-content: center;
    /* width: 500px; */
}

.content-header-title-right{
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 0px;
    box-shadow: 0px 4px 4px rgba(0,0,0,.25),4px 0px 4px rgba(0,0,0,.25) ;
}

.content-header-title-center{
    border-bottom-left-radius: 19px;

}

.content-header-subtitle{
    font-weight: 400;
    font-size: 1rem;
    line-height: 0;
}

.content-header-title-subtitle{
    margin-bottom: 4px;
}

.left-child {
    flex: 0 0 auto; /* Prevents the left child from stretching to fill remaining space */
  }

.center-child {
    flex: 1; /* Allows the center child to take up remaining space */
    text-align: center; /* Centers the content within the child element */
  }

.rl-tab{
    /* padding: 10px 25px 25px 25px; */
    line-height: 2.5rem !important;
    transition: all 0.2s ease-in-out;
}

.rl-tab:hover {
  background-color: #5B9398;
  transform: scale(1.1);
}


.rl-tab-circle::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(-50%, -50%);
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: white;
  }


 