table.prTable {
  border-collapse: collapse;
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  
  border-radius: 19px;
  border-bottom: none;
  /* width: 100%; */
}

table.prTable td{
  border: 1px solid #b9b9b9;
  padding: 8px;
}

.divPlaceHolder {
  box-shadow : 54px 23px 0px 20px white;
  top: 78px;
}

.aggregateTh {
  border-radius: 0px;
}


table.prTable th{
  /* background-color: #153E5C; */
  /* background: linear-gradient(0deg, #163A54 0%, rgba(12, 34, 51, 0.00) 232.93%); */
  background: linear-gradient(0deg, #163A54 0%, #ffffff 232.93%);
  height: 40px;
  /* Paper DropShadow */
 
  font-weight: 400;
  color: white;
  text-align: center;
  padding: 8px;
  white-space: nowrap;  
  outline: 1px solid #b9b9b9;
  /* background-color: white;  */
  width:200px;
  overflow: hidden;

}

.topRowTH {
  position : sticky;
  top :80px;
}

.bottomRowTH {
  position : sticky;
  top : 120px;
}

table.prTable th.topRowTH:first-of-type {
  border-top-left-radius: 19px;
  border : none;
}

table.prTable th.topRowTH:last-of-type {
  border-top-right-radius: 19px;
  border : none;
}

table.prTable tr:last-child td:first-child {
  border-bottom-left-radius: 19px;
  border : none;
}

table.prTable tr:last-child td:last-child {
  border-bottom-right-radius: 19px;
  border : none;
}

/* table.prTable thead tr{
  position : sticky; 
  z-index : 50;
  top: 79px;
} */

table.prTable td {
  white-space: nowrap; /* Prevent text from wrapping within the cell */
  overflow: hidden; /* Hide content that overflows the cell */
  text-overflow: ellipsis; /* Display an ellipsis (...) for overflowed content */
}

td.prDetail {
  background-color: white;
}

td.grandTotal {
  background-color: #153E5C;
  color: white;
}

td.prTotal1 {
  background-color: #1E547C;
  color: white;
}

td.prTotal2 {
  background-color: #266C9E;
  color: white;
}

td.prTotal3 {
  background-color: #398CC9;
  color: white;
}

td.prTotal4 {
  background-color: #63A5D6;
  color: white;
}

td.prTotal5 {
  background-color: #91BBD9;
  color: white;
}

td.prTotal6 {
  background-color: #A6CBE7;
  color: white;
}

.filterSelections{
  min-width: 16rem;
  width :auto !important;
  white-space: nowrap;
}