@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .content{ margin-left: 275px; transition: margin-left .3s ease-in-out;}
  .expandedContent{ margin-left: 45px; transition: margin-left .3s ease-in-out;}
}

body{
    font-family: 'Exo 2', sans-serif;
    font-weight: 400;
    color:black;
}

.primary-light-btn {
  @apply rounded-lg bg-primary-light font-normal hover:rounded-lg hover:font-normal;
}

.required{    color : #D60000;}


/* Flexbox */
/* .flex {display: flex;}
.justify-between {justify-content: space-between}
.justify-right {justify-content: flex-end}
.justify-center {justify-content: center}
.content-center	{align-content: center;}
.grow	{flex-grow: 1;}
.flex-col  {flex-direction: column;} */

/* Grid */
.grid	{display: grid;}
.items-center{	align-items: center;}
.items-start{	align-items: flex-start;}

/* Misc */
.float-right{float: right; color:red}
/* 
.cursor-pointer {	cursor: pointer }

.text-white{color:white;}
.text-center	{text-align: center;}
.text-right	{text-align: right;}
.text-left	{text-align: left;} */

/* Font */
.font-bold	{font-weight: 700;}
.text-xl	{font-size: 1.25rem; /* 20px */
line-height: 1.75rem; /* 28px */}
.text-2xl	{font-size: 1.5rem; /* 24px */
    line-height: 2rem; /* 32px */}
/* .font-normal	{font-weight: 400;} */
.truncate {white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}

.text-blue-500 {	color: rgb(59 130 246);}

.hover-underline:hover	{text-decoration: underline;}

/* Overflow */
.overflow-hidden	{overflow: hidden;}

.my-1{margin-top: 0.25rem; /* 4px */ margin-bottom: 0.25rem; /* 4px */}
.my-2{margin-top:.5rem; margin-bottom: .5rem}
.my-5{margin-top: 1.25rem; margin-bottom: 1.25rem;}
.my-10{margin-top: 5rem; margin-bottom: 5rem;}
.my-20{margin-top: 10rem; margin-bottom: 10rem;}

.mx-16  {margin-left: 4rem; /* 64px */ margin-right: 4rem; /* 64px */}

.mb-2	{margin-bottom: 0.5rem; /* 12px */}
.mb-3	{margin-bottom: 0.75rem; /* 12px */}
.mb-4 {margin-bottom: 1rem; /* 12px */}

/* Line Height */
.leading-3	{line-height: .75rem; /* 12px */}
.leading-5 {line-height: 1.25rem; /* 20px */}
.leading-6 {line-height: 1.5rem; /* 24px */}
.leading-7 {line-height: 1.75rem; /* 28px */}
.leading-10  {line-height: 2.5rem; /* 40px */}
.leading-8{	line-height: 2rem; /* 40px */}
.leading-10{	line-height: 2.5rem; /* 40px */}
.leading-15{    line-height: 3.5rem;}

.overflow-visible {overflow: visible;}

/* Color Palette */
.sj-bg-primary { background-color: #153e5C;}
.sj-color-primary { color: #153e5C;}
.sj-bg-primary-dark { background-color: #0C2233;}
.sj-bg-primary-light { background-color: #2973A9;}
.sj-bg-secondary-dark {background-color: #5B9398 !important;}
.sj-bg-grey-500{background-color: #758B8E;}

.color-white  {color: white;}
.sj-bg-grey-100{background-color: #EFEFEF;}
.sj-bg-grey-200{background-color: #D9D9D9;}
.sj-bg-C3 { background-color: #C3C3C3;}
.danger{    background-color : #DC4F30;}
.bg-white	{background-color: rgb(255 255 255);}
.bg-disabled {	background-color: #e9ecef;}

/* .shadow-sj	{  box-shadow: 0px 4px 4px rgba(0,0,0,.25),4px 0px 4px rgba(0,0,0,.25) ;} */
/* .shadow-sj-btm	{  box-shadow: 0px 4px 4px rgba(0,0,0,.25) ,0px 0px 4px rgba(0,0,0,.25) ;} */

/* Positioning */
/* .sticky{  position: -webkit-sticky; position: sticky;}
.relative	{position: relative;}
.absolute	{position: absolute;}
.fixed	{position: fixed;} */
.left-0 {left: 0px;}
.top-0 {top:0px;}
.left-200 {left: 200px;}
.right-0{right:0px;}
.top-10	{top: 2.5rem; /* 40px */}
.top-20	{top: 5rem; /* 40px */}
.top-30	{top: 7.5rem; /* 40px */}
.left-1-2 {left:50%;}
.translate-x-2-4	{transform: translateX(-50%);}

.min-h-screen{min-height: 100vh;}
.min-w-full	{min-width: 100%;}
.w-full	{width: 100%;}
.w-2-3	{width: 66.666667%;}
.w-7th  {width: 14.2857143%;}
.w-half {width: 50%;}
.w-9-12	{width: 75%;}

.h-9-12  {height: 75%;}

.z-1000	{z-index: 1000;}
.z-100  {z-index: 100;}
.z-90  {z-index: 90;}
.z-80  {z-index: 80;}
.z-70  {z-index: 70;}
.z-50	{z-index: 50;}
.z-40	{z-index: 40;}
.z-30	{z-index: 30;}
.z-25  {z-index: 25;}
.z-20	{z-index: 20;}

/* Translation */
.rotate-180	{transform: rotate(180deg);}
.moveUp5 {transform: translateY(-3px);}

/* Borders */ 
.border-none	{border-style: none;}
.rounded-tr-none	{border-top-right-radius: 0px;}
.rounded-tl-none    {border-top-left-radius: 0px;}
.rounded-br-none	{border-bottom-right-radius: 0px;}

.rounded-top-2xl {border-top-left-radius: 19px; border-top-right-radius: 19px; }
.rounded-bottom-2xl {border-bottom-left-radius: 19px; border-bottom-right-radius: 19px; }
.rounded-2xl {border-radius: 19px !important;}
.rounded-tl-2xl	{border-top-left-radius: 19px; }
.rounded-tr-2xl	{border-top-right-radius: 19px; }
.rounded-bl-2xl	{border-bottom-left-radius: 19px; }
.rounded-br-2xl	{border-bottom-right-radius: 19px; }
.rounded-left-2xl  {border-top-left-radius: 19px; border-bottom-left-radius: 19px; }
.rounded-right-2xl {border-top-right-radius: 19px; border-bottom-right-radius: 19px; }

.row-hover:hover{background-color: #A4F7FF !important;}

.rotate-180 {
    transform: rotate(180deg);
  }
  .rotate-270 {
    transform: rotate(270deg);
  }
  
  /* .rotate-n90 {
    transform: rotate(-90deg);
    transition: all 0.3s ease-in-out;
  } */

  .rotate-270 {
    transform: rotate(270deg);
    transition: transform 0.3s ease-in-out;
  }

  .transition-arrow-all {
    transition: all 0.3s ease-in-out;
  }
  .transition-arrow-all2 {
    transition: all 0.3s ease-in-out;
  }
  .transition-arrow-transform{
    transition: transform 0.3s ease-in-out;
  }

  /* increase size by 3x */
 
.scale-2{
  scale : 2;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-in-out; /* Adjust the duration and easing as needed */
}

.shift-right{
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.5s ease-in-out;

}

.RLPlaceHolder {
  height: 207px;
  width: 100%;
  background-color: #FFFFFF;
  border-bottom-left-radius: 19px;
  border-bottom-right-radius: 19px;
  box-shadow:  inset 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
}


.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #0000EE;
  text-decoration: underline;
  cursor: pointer;
}

.link-loading {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: black;
}