.left-nav{
    position: fixed;
    top: 79px;
    font-weight: 400;
    overflow-x: hidden;
    left: 0;
    z-index: 1;
    /* display: flex;
    flex-direction: column;
    align-items: flex-start; */
    height: 100%;
    line-height: 1.8rem;
    box-shadow: 4px 0px 4px rgba(0,0,0,.25);
}

.left-nav-link:hover{
    color:rgb(219, 219, 219)
}

.left250{
    /* position: fixed;
    right: 250px;
    transition: right .3s ease-in-out; */
    position: fixed;
    left: 259px;
}

.left60{
    /* position:fixed;
    right: 60px;
    transition: right .3s ease-in-out; */
    position: fixed;
    left: 21px;
}

.bottom-right {
    position: absolute;
    bottom: 10%;
    right: 10%;
    transition: right .3s ease-in-out;
  }

  .bottom-right-collapse {
    position: absolute;
    bottom: 10%;
    right: 37%;
    transition: right .3s ease-in-out;
  }

.width-275{
    width: 275px;
    transition: width .3s ease-in-out;
}

.width-75{
    width: 45px;
    transition: width .3s ease-in-out;
}

