
.timeSheetTotals {
    height: 105px;
    /* width: 75%; */
    border-radius: 19px;
    box-shadow: 0px 4px 4px rgba(0,0,0,.25),4px 0px 4px rgba(0,0,0,.25), inset 0 4px 0px 0px rgba(0, 0, 0, 0.25);
    /* transform: translateY(-50px);  */
  }

  .timeEntryTotals {
    height: 50px;
    border-radius: 19px;
    top: 80%;
    left: 85%;
    width: 100px;
  }

  /* .timeEntryDayTotal {
    border-right : 1px solid #D9D9D9;
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    height: 100%;
    line-height: 2;
    padding-top: 5px;
    flex-grow: 1;
 } */

 .timeEntry-nonWorking-circle::before {
  content: "";
  position: absolute;
  transform: translate(150%, 360%);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #C6E4F5;
 }
 .timeEntry-holiday-circle::before {
  content: "";
  position: absolute;
  transform: translate(150%, 360%);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color:rgb(255, 255, 217) 
 }
  

  .separator {
    width: 1px; /* Width of the separator line */
    background-color: #333; /* Color of the separator line */
    height: 50%; /* Adjust the height to center the line vertically */
  }

  .timeEntry {
    /* height: 207px; */
    /* width: 75%; */
    background-color: #CDCDCD;
    border-radius: 19px;
    box-shadow:  inset 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }


  .timeEntryText {
    font-size: 1rem;
    font-weight: 400;
    margin : 5px;
    text-align: left;
  }

  .timeEntryDayHeaders{
    /* font-size: 1rem; */
    font-weight: 400;
    text-align: center;
    background-color: #CDCDCD !important;
  }

  .timeEntryDayNumber{
    box-shadow: none !important; 
    text-align: center;
  }

  
  .nonWorkingTime{
    background-color: #C6E4F5 !important;
  }

  .holiday{
    background-color: rgb(255, 255, 217) !important;
  }

  .preventEvents{
    pointer-events: none; /* Prevents mouse events, including scrolling */
  }
