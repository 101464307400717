div .btn-filter {
   font-size: 1.25rem;
   padding: 8px 10px !important;
   height: 2.5rem;
   width: 4rem;
}

div .btn-or{
    padding-left: 17px !important;
    padding-right: 37px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-size: 1.25rem;
    height: 2.4rem;
    width: 2.4rem;
}


div .btn-and {
    font-size: 1.25rem;
    padding: 8px 9px !important;
    height: 2.4rem;
    width: 3.4rem;
 }

div .btn-logic:hover{
    background-color: #48767a !important;
}


.btn-delete{
    translate: 4px -3px;
}

.and{
    position: absolute; 
    /* border-left: 2px solid white;
    border-top: 2px solid white;*/
    border-bottom: 2px solid white; 
    height: 56px;
    width: 30px;
    /* top: -50%;
    left: 38px; */
}

.or{
    position: absolute;
    border-left: 2px solid white;
    /* border-top: 2px solid white; */
    border-bottom: 2px solid white;
    height: 39px;
    width: 30px;
    /* top: -20%;
    left: 38px; */
}

.oor{
    border-bottom: 2px solid white;
    width: 90%;
    margin-left: 87px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.or-row{
    margin-left: 5rem;
}

.and-text{
    left: -17px;
    position: absolute;
    top: 78%;
    background-color: #c3c3c3;
    color: white;
    padding-right: 5px;
}

.or-text{
    left: -12px;
    position: absolute;
    top: 70%;
    background-color: #c3c3c3;
    color: white;
    padding-right: 5px;
}

.oor-text{
    position: absolute;
    background-color: #c3c3c3;
    color: white;
    padding-left: 8px;
    translate: -40px -11px;
}