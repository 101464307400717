

/* Bootstrap Overrides */
.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited{
    background-color: #37BDE8;
    border-radius: 10px;
    box-shadow: 5px 5px 4px rgba(0,0,0,.5);
    border: none;
    /* padding: 7px 20px; */
    font-weight: 700;
    letter-spacing: 1px;
    min-height: 2.5rem;
}

.btn-primary:disabled{
    background-color: #a4bcc4;
    border-radius: 10px;
    box-shadow: 5px 5px 4px rgba(0,0,0,.5);
    border: none;
    /* padding: 7px 20px; */
    font-weight: 700;
    letter-spacing: 1px;
    min-height: 2.5rem;
}

.btn-primary:hover{
    background-color: #26a5cc !important;
}

.btn-secondary, .btn-secondary:hover, .btn-secondary:active, .btn-secondary:visited{
  background-color: #2973A9 !important; 
  border-radius: 10px;
  box-shadow: 5px 5px 4px rgba(0,0,0,.5);
  border: none;
  padding: 7px 20px;
  font-weight: 700;
  letter-spacing: 1px;
  width : 16rem;
}

.btn-secondary:hover{
  background-color: #1d4f74 !important;
}

.btn-info, .btn-info:active, .btn-info:visited{
  background-color: #5B9398 !important; 
  border-radius: 10px;
  box-shadow: 5px 5px 4px rgba(0,0,0,.5);
  border: none;
  padding: 7px 20px;
  font-weight: 700;
  letter-spacing: 1px;
  width : 16rem;
  color: white;
}

.btn-info:hover{
  background-color: #477175 !important;
  color: white;
}

.btn-light, .btn-light:hover, .btn-light:active, .btn-light:visited{
  background-color: #ffffff !important; 
  border-radius: 10px;
  box-shadow: 4px 4px 4px rgba(0,0,0,.25);
  border: none;
  padding: 7px 20px;
  /* letter-spacing: 1px; */
  min-width: 200px;
  border-radius: 19px;
  padding-right: 36px;
}

.btn-danger, .btn-danger:hover, .btn-danger:active, .btn-danger:visited{
    background-color: #DC4F30 !important; 
    border-radius: 10px;
    box-shadow: 5px 5px 4px rgba(0,0,0,.5);
    border: none;
    padding: 7px 28px;
    font-weight: 700;
    letter-spacing: 1px;
}

.btn-danger:hover{
    background-color: #b84228 !important;
}

.btn-danger-modal, .btn-danger-modal:hover, .btn-danger-modal:active, .btn-danger-modal:visited{
    background-color: #DC4F30 !important; 
    border-radius: 0px 19px 0px 19px;
    box-shadow: 5px 5px 4px rgba(0,0,0,.5);
    border: none;
    padding: 15px 60px;
    font-weight: 700;
    letter-spacing: 1px;
}

.btn-danger-modal:hover{
    background-color: #b84228 !important;
}


.sj-bg-btn-primary{background-color: #37BDE8 !important;
border: none;
padding: 7px 20px;
font-weight: 700;
letter-spacing: 1px;}

.sj-bg-btn-primary:hover{
    background-color: #26a5cc !important;
}

.dropdown-toggle::after{
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 36px;
    height: 100%;
    background-color: #37BDE8;
    z-index: 1;
    border-bottom-right-radius: 19px;
    border-top-right-radius: 19px;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
}
.dropdown-toggle::before {
    content: '▼';
    display: block;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    /* font-size: 12px; */
    z-index: 2;
  }

  .form-control{
    border-radius: 19px;
    box-shadow: 4px 4px 4px rgba(0,0,0,.25);
}

.form-field-error{
    color : #D60000;
    margin-left : .25rem;
    font-size: 15px;
    /* font-weight: 700; */
}

.dropdown-item:active{
    background-color: #37BDE8;
}

.dropdown-menu .dropdown-item:last-child:hover{
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;
}

.dropdown-menu .dropdown-item:first-child:hover{
    border-top-left-radius: 19px;
    /* border-bottom-right-radius: 19px; */
}

.modal-content{
    border-radius: 19px;
}

.tooltip-inner {
    background-color: #0C2233;
    box-shadow: 0px 4px 4px rgba(0,0,0,.25),4px 0px 4px rgba(0,0,0,.25) ;
    font-family: 'Exo 2', sans-serif;
    font-weight: 400;
    border-radius: 19px;
    padding : 5px 15px;
}

.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #0C2233;
  }

  textarea::-webkit-scrollbar {
    width: 16px;
  }
  
  textarea::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 19px;
  }
  
  textarea::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 19px;
  }
  
  textarea::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  .form-check-input {
    width: 2em;
    height: 2em;
    
  }

  .form-check-input[type=checkbox] {
    border-radius: 9px;
}

  .form-check-input:checked {
    background-color: #153e5C ;
    border-color: #153e5C ;
}

.form-check-label{
    line-height: 2.5;
    margin-left: .65em
}

.alert-danger {
    color: #ffffff;
  background-color: #b84228 ;
  border-color: #b84228 ;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
  
    > [class^="col-"],
    > [class*=" col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .input-group-text{
   background-color: #f5f5f5;
   border-radius: 19px;
   box-shadow: 4px 4px 4px rgba(0,0,0,.25);
  }

  .verification-input {
    width: 200px;
    height: 50px;
    text-align: center;
    font-size: 20px;
}