.nav-bottom-border{
    border-bottom: 7px solid #5B9398;
}

.header-link{
    color: white;
}
.navbar-nav .nav-link.active{
    color: white; 
    /* border-bottom: 2px white solid; */
}

.navbar-nav :hover{
    color: rgb(200, 200, 200);
}

.circle {
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
  }

.circle .navbar-nav :hover{
    color: #0C2233;
}
