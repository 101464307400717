.option {
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    flex-grow: 1;
    width: 100%;
    background-color: #fff;
}

.option-odd {
    background-color: #EFEFEF;
}


.option-selected {
    background-color: #2973A9;
    color: #fff;
}

.option:hover {
    background-color: #e2e8f0;
    color: black
}

.option-selected:hover {
    background-color: #1d5379;
    color: #fff;
}

.checkbox {
    height: 24px;
}